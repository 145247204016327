var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rtl-message-filter-handler-module-view"},[_c('page-title',{attrs:{"title":_vm.$t(_vm.module.model.titleKey),"subtitle":_vm.$te(_vm.module.model.pageMiniHelpMessageKey) ? _vm.$t(_vm.module.model.pageMiniHelpMessageKey) : null,"help-message":_vm.pageHelpMessageOrDescription(_vm.module.model)},scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [(_vm.hasBackButton)?_c('go-back-button',{attrs:{"title":_vm.backButtonTitle},on:{"go-back":_vm.goBack}}):_vm._e(),_c('save-module-button',{on:{"save-module":_vm.saveModule}})]},proxy:true}])}),_c('module-config-settings-wrapper',{attrs:{"has-conditions":_vm.module.model.config.conditionsExists}},[_c('module-config-settings',{model:{value:(_vm.module.model.config),callback:function ($$v) {_vm.$set(_vm.module.model, "config", $$v)},expression:"module.model.config"}})],1),_c('filter-setting-layout',{attrs:{"filter-model":_vm.module.model},scopedSlots:_vm._u([{key:"tab-main",fn:function(){return [_c('a-card',{attrs:{"title":_vm.$t('field_special_settings_title')}},[_c('number-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module.model.params,
              'key': 'rtl_message_max_percent',
              'hasAnchor': true,
              'min': 0,
              'max': 100,
              'formatter': function (value) { return (value + "%"); },
              'parser': function (value) { return value.replace('%', ''); },
              'settingWrapper': {
                customiseValue: 10,
                hasFieldButton: true,
                disabledValue: 0
              }
            }
          }}}),_c('handle-user-bio-field',{staticClass:"mb-0",model:{value:(_vm.module.model.filter.handle_user_bio),callback:function ($$v) {_vm.$set(_vm.module.model.filter, "handle_user_bio", $$v)},expression:"module.model.filter.handle_user_bio"}})],1),_c('filter-journal',{attrs:{"warn-field":_vm.module.model.journal_moderation_warn,"ban-field":_vm.module.model.journal_moderation_ban},on:{"update:warnField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:warn-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_warn", $event)},"update:banField":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)},"update:ban-field":function($event){return _vm.$set(_vm.module.model, "journal_moderation_ban", $event)}}}),_c('filter-extra-settings',{attrs:{"ignore-caption":""},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-regular",fn:function(){return [_c('filter-common-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-new",fn:function(){return [_c('filter-new-users-settings',{attrs:{"fields-prefix":_vm.module.model.snakeCaseFilterType},model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true},{key:"tab-edit",fn:function(){return [_c('filter-remove-edited-setting',{model:{value:(_vm.module.model.filter),callback:function ($$v) {_vm.$set(_vm.module.model, "filter", $$v)},expression:"module.model.filter"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }